import { format, distanceInWords, differenceInDays } from "date-fns";
import React from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import PortableText from "./portableText";
import Container from "./container";
import AuthorList from "./author-list";
import HeroNoImage from "./hero-no-image";
import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton
} from "react-share";
import { FacebookIcon, LinkedinIcon, RedditIcon, TwitterIcon } from "react-share";

const BlogPost = props => {
  const { _rawBody, authors, categories, title, mainImage, publishedAt, slug } = props;
  const websiteUrl = `https://enkeltoggreit.online`;
  const dateSegment = format(publishedAt, "YYYY/MM");
  const path = `/blog/${dateSegment}/${slug.current}/`;
  const postUrl = websiteUrl + path;
  console.log(categories);
  return (
    <article className="article">
      {mainImage && mainImage.asset && (
        <div className="article__main-image">
          <img
            src={imageUrlFor(buildImageObj(mainImage))
              .width(1200)
              .height(Math.floor((9 / 16) * 1200))
              .fit("crop")
              .auto("format")
              .url()}
            alt={mainImage.alt}
          />
        </div>
      )}
      <HeroNoImage post={true} />
      <Container>
        <div className="article__grid">
          <div className="article__main-content">
            <h1 className="article__title">{title}</h1>
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>
          <aside className="article__meta-content">
            {authors && <AuthorList items={authors} title="" />}
            {publishedAt && (
              <div>
                <h2 className="article__aside-header">Dato</h2>
                <div className="article__published-at">
                  {differenceInDays(new Date(publishedAt), new Date()) > 3
                    ? distanceInWords(new Date(publishedAt), new Date())
                    : format(new Date(publishedAt), "DD-MM-YYYY")}
                </div>
              </div>
            )}
            {categories && categories.length > 0 && (
              <div className="article__categories">
                <h2 className="article__aside-header">Kategorier</h2>
                <ul>
                  {categories.map(category => (
                    <li key={category._id}>{category.title}</li>
                  ))}
                </ul>
              </div>
            )}
          </aside>
        </div>
        <div className="article__share-box">
          <h3>Del på sosiale medier</h3>
          <span>
            <FacebookShareButton url={postUrl}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
          </span>
          <span>
            <LinkedinShareButton url={postUrl}>
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
          </span>
          <span>
            <RedditShareButton url={postUrl}>
              <RedditIcon size={32} round={true} />
            </RedditShareButton>
          </span>
          <span>
            <TwitterShareButton url={postUrl}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
          </span>
        </div>
      </Container>
    </article>
  );
};

export default BlogPost;
